import React from "react"
import Header from "./header"
import Footer from "./footer"
import styles from "./layout.module.css"


class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div id={styles.main_layout}>
        <Header />
        <div id={styles.content}>
          <main className={styles.main_content}>{children}</main>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
