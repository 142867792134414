import React from "react"
import { Link } from "gatsby"

import styles from "./header.module.css"


class Header extends React.Component {
  render() {
    const NavigationItem = props => (
      <li>
        <Link className={styles.link}
          style={this.props.style}
          activeClassName={styles.link_active}
          partiallyActive={true}
          to={props.to}>
            <span className={styles.link_hash}>#</span>
            {props.children}
        </Link>
      </li>
    )

    return (
      <div id={styles.header_wrapper}>
        <div id={styles.header}>
          <h2>
            <Link to={`/`}>
              <span className={styles.title_symbol}>{'<'}</span>
              <span>laury.dev</span>
              <span className={styles.title_symbol}>{'/>'}</span>
            </Link>
          </h2>
          <nav className={styles.navigation_container}>
            <ul className={styles.navigation_list}>
              <NavigationItem to="/projects">projects</NavigationItem>
              {/* <NavigationItem to="/talks">talks</NavigationItem> */}
              <NavigationItem to="/snippets">snippets</NavigationItem>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header
