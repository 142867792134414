import React from "react"
import { Link } from "gatsby"
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa"
import styles from "./footer.module.css"


class Footer extends React.Component {
  render() {
    return (
      <footer id={styles.footer_wrapper}>
        <div id={styles.first_section}>
          <div className={styles.about}>
            <h2>
              <Link to={`/`}>
                <span className={styles.title_symbol}>{'<'}</span>
                <span>laury.dev</span>
                <span className={styles.title_symbol}>{'/>'}</span>
              </Link>
            </h2>
            <div className={styles.contact}>
              <a href="https://github.com/laurybueno" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
              <a href="https://www.linkedin.com/in/laurybueno/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a href="mailto:laury.bueno@gmail.com" target="_blank" rel="noopener noreferrer">
                <FaEnvelope />
              </a>
            </div>
            <div className={styles.byline}>
              layout by&nbsp;
              <a href="https://www.cardume.art.br/" target="_blank" rel="noopener noreferrer">
                <span className={styles.authors}>cardume</span>
              </a>&nbsp;
              <span className={styles.symbols}>+</span>&nbsp;
              <a href="https://hacklab.com.br/" target="_blank" rel="noopener noreferrer">
                <span className={styles.authors}>hacklab</span>
                <span className={styles.symbols}>/</span>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.second_section}>
          <div className={styles.navigation}>
            <Link to="/projects">
              <span>#</span>projects
            </Link>
            {/* <Link to="/talks">
              <span>#</span>talks
            </Link> */}
            <Link to="/snippets">
              <span>#</span>snippets
            </Link>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
